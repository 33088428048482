import React from 'react';
import PropTypes from 'prop-types';
import SiteSummaryImages from './site-summary-images/site-summary-images';
import './portfolio-card.css';

export default class SiteSummaryCard extends React.Component {
  render() {
    // eslint-disable-next-line react/prop-types
    // console.log(profile);

    let srcLink = "";

    if(this.props.isSrcPublic){
      srcLink = <a href={this.props.srcCode} target="_blank" rel="noreferrer">Source</a>;
    }

    return (
      <li className="task-item" >
        <h4>{this.props.siteName}</h4>
        <div className="info" >
          <span className="siteImages"> {/*Image place holder*/}
            <SiteSummaryImages {...this.props.imageProperties} />
          </span>
          <p className="details" >{this.props.siteDetails}</p>
          <a href={this.props.siteLink} target="_blank" rel="noreferrer">Site</a>
          {srcLink}
        </div>
      </li>
    );
  }
}

SiteSummaryCard.propTypes = {
  siteName: PropTypes.string.isRequired,
  siteDetails: PropTypes.string.isRequired,
  siteLink: PropTypes.string.isRequired,
  isSrcPublic: PropTypes.bool.isRequired,
  srcCode: PropTypes.string,
  imageProperties: PropTypes.object
};
