import React from 'react';
import PropTypes from 'prop-types';
import './site-summary-images.css';

export default class SiteSummaryImages extends React.Component {
  render() {
    //console.log(this.props);
    return (
      <div className="imageOverLap" style={{background: this.props.background}} >
        <img className="mobileImage" src={this.props.imgMobile} alt={this.props.imgMobileAlt} />
        <img className="desktopImage" src={this.props.imgDesktop} alt={this.props.imgDeskAlt} />
      </div>
    );
  }
}

SiteSummaryImages.propTypes = {
  background: PropTypes.string,
  imgDesktop: PropTypes.string,
  imgDeskAlt: PropTypes.string,
  imgMobile: PropTypes.string,
  imgMobileAlt: PropTypes.string
};