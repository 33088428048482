import React from 'react';

import SocialMediaBar from '../social-media/social-media-bar';

import './footer.css';

class Footer extends React.Component {
  constructor(){
    super();

    this.state = {
      authorName: "Brad Mouck",
      year: new Date()
    };
  }

  render() {
    return (
      <footer id="footer-cite">
        <div id='authorNote'>&copy; {this.state.year.getFullYear()} and coded by <cite>{this.state.authorName}</cite></div>
        <ul id='contactEmail'>
          <li className='bold'>Email Address</li>
          <li>brad@thebtm.ca</li>
        </ul>
        <SocialMediaBar pageLocation='smFooter'/>
      </footer>
    );
  }
}

export default Footer;
