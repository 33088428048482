export default class SiteSummary {
  /**
   * Just a object for managing all the sites, will be replaced with a server side code.
   * @param {String} siteName 
   * @param {String} siteDetails 
   * @param {String} siteLink 
   * @param {String} srcCode 
   * @param {Boolean} isSrcPublic 
   * @param {Object} imageProperties
   */
  constructor(siteId, siteName, siteDetails, siteLink, srcCode, isSrcPublic, imageProperties){
    this.siteId = siteId;
    this.siteName = siteName;
    this.siteDetails = siteDetails;
    this.siteLink = siteLink;
    this.srcCode = srcCode;
    this.isSrcPublic = (isSrcPublic)? true : false;
    this.imageProperties = imageProperties;
  }
}