import React from 'react';
import PropTypes from 'prop-types';

import './experience-card.css';

export default class ExperienceCard extends React.Component {

  render() {
    // console.log("Experience Card props", this.props);
    let count = 0;
    return (
      <li className="experience-card" >
        <h4 className="experience-title">{this.props.title}</h4>
        <span className="experience-company">{this.props.company}</span>
        <span className="experience-location">{this.props.location}</span>
        <span className="experience-period">{this.props.period}</span>
        <ul className="experience-description">{this.props.description.map(entry => <li key={count++}>{entry}</li>)}</ul>
      </li>
    );
  }
}

ExperienceCard.propTypes = {
  title: PropTypes.string,
  company: PropTypes.string,
  location: PropTypes.string,
  period: PropTypes.string,
  description: PropTypes.array
};
