import React from 'react';
import PropTypes from 'prop-types';
import SocialMediaIcon from './icon/social-media-icon';

import './social-media-bar.css';

/*
{
    href: 'https://gitlab.thebtm.ca:8443/thebtm',
    icon: 'gitlab',
    title: 'Private Gitlab'
  }
*/

const socialMediaList = [
  {
    href: 'https://www.linkedin.com/in/bradmouck/',
    icon: 'linkedin',
    title: 'linkedin'
  },
  {
    href: 'https://www.gitlab.com/thebtm',
    icon: 'gitlab',
    title: 'gitlab.com'
  },
  {
    href: 'https://www.github.com/thebtm',
    icon: 'github',
    title: 'github'
  },
  {
    href: 'https://dev.to/thebtm',
    icon: 'dev',
    title: 'dev'
  }
];

export default class SocialMediaBar extends React.Component {
  render(){
    let count = 0;
    return (
      <div className={this.props.pageLocation} >
        <ul className='social-media-list' >
          {socialMediaList.map(item => <SocialMediaIcon key={count++} {...item} /> )}
        </ul>
      </div>
    );
  }
}

SocialMediaBar.propTypes = {
  pageLocation: PropTypes.string
};
