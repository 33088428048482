import React from 'react';
import { NavLink } from 'react-router-dom';
import SocialMediaBar from '../social-media/social-media-bar';
import './navbar.css';


export default class NavBar extends React.Component {
  constructor(props) {
    super(props);
    //const [isOpen, setOpen] = useState(false);
    this.state = {
      isOpen: false
    };
  }

  render() {
    const navbarItem = "navbar-item";
    return (
      <nav >

        <NavLink
          className={(navData) => navData.isActive ? "is-active " + navbarItem : navbarItem}
          to="/home"
        >
          Home
        </NavLink>

        <NavLink
          className={(navData) => navData.isActive ? "is-active " + navbarItem : navbarItem}
          to="/experience"
        >
          Experience
        </NavLink>

        <NavLink
          className={(navData) => navData.isActive ? "is-active " + navbarItem : navbarItem}
          to="/portfolio"
        >
          Portfolio
        </NavLink>
        <SocialMediaBar pageLocation='navbar' />
      </nav>
    );
  }
}
