// *** Node Modules Imports *** /
import React from 'react';
// import PropTypes from 'prop-types';

// ***  Components *** /
import MainBody from './components/main-body';

/**
 * This is the entry point of the ReactApp
 */
function App() {
  return (

    <MainBody placeholder="this site is in Beta" />

  );
}

export default App;
