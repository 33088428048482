import React from 'react';
import PropTypes from 'prop-types';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab, faLinkedin, faGithub, faGitlab, faDev } from '@fortawesome/free-brands-svg-icons';

import './social-media-icon.css';

library.add(fab, faLinkedin);
library.add(fab, faGithub);
library.add(fab, faGitlab);
library.add(fab, faDev);

export default class SocialMediaIcon extends React.Component {
  render() {
    return (
      <li>
        <a href={this.props.href} className='icon' target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={['fab', this.props.icon]} title={this.props.title} size='2x' />
        </a>
      </li>
    );
  }
}

SocialMediaIcon.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
