import React from 'react';
// import PropTypes from 'prop-types';
import PortfolioCard from './portfolio-card';
import SiteSummary from '../../logic/site-summary-class';
import './portfolio.css';

export default class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    const thisSite = new SiteSummary(
      0,
      "Self Website / This Site",
      "I was experimenting with ReactJS and React Router to come up with this site. It eventually turned into my developer and experience site.",
      "/portfolio",
      "",
      false,
      {
        background: "linear-gradient(to bottom,hsl(0, 0%, 18%),hsl(0, 0%, 12%))",
        imgDesktop: "img/portfolio-desktop.png",
        imgDeskAlt: "Alt Desktop Line",
        imgMobile: "img/portfolio-mobile.png",
        imgMobileAlt: "Alt Mobile Image"
      }
    );
    const firstSite = new SiteSummary(
      1,
      "ICT580 HTML5 CSS3 Assignment",
      "This was an assignment to show that I had learned from the HTML5 CSS 3 class from the U of C. I got 100% on the class after all assignments and quizes",
      "https://ict580.thebtm.ca/",
      "",
      false,
      {
        background: "#343434",
        imgDesktop: "img/ict580-desktop.png",
        imgDeskAlt: "Alt Desktop Line",
        imgMobile: "img/ict580-mobile.png",
        imgMobileAlt: "Alt Mobile Image"
      }
    );
    const secondSite = new SiteSummary(
      2,
      "Frontend Mentors FAQ Challange",
      "This is the completed challenge for the frontend mentors FAQ Accordian challenge. I even went the extra step of trying to complete it solely on HTML and CSS.",
      "https://femio.thebtm.ca/faq-accordion-card/",
      "https://github.com/thebtm/faq-accordion-card-main",
      true,
      {
        background: "linear-gradient(to bottom,hsl(273, 75%, 66%),hsl(240, 73%, 65%))",
        imgDesktop: "img/femio-faq-desktop.png",
        imgDeskAlt: "Alt Desktop Line",
        imgMobile: "img/femio-faq-mobile.png",
        imgMobileAlt: "Alt Mobile Image"
      }
    );


    this.state = {
      siteList: [thisSite, firstSite, secondSite]
    };
  }

  render(){
    return (
      <ul className="site-list">
        {this.state.siteList.map(site => <PortfolioCard key={site.siteId} {...site} />)}
      </ul>
    );
  }

}
