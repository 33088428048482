import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Header from './header/header';
import NavBar from './navbar/navbar';
import Home from './home/home';
import Experience from './experience/experience';
import Portfolio from './portfolio/portfolio';
import Footer from './footer/footer';
import Error404 from './error404';

export default class MainBody extends React.Component {

  render() {

    return (
      <BrowserRouter>
        <Header />
        <NavBar />
        <main>
          <Routes>
            <Route exact path="/home" element={<Home />} />
            <Route path="/experience" element={
              <>
                <Experience title="Employment" />
                <Experience title="Education" />
              </>
            } />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/404" element={<Error404 />} />
            <Route exact path='/' element={<Navigate to='/home' /> } />
            <Route path='/*' element={<Navigate to='/404' /> } />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>

    );
  }
}

MainBody.propTypes = {
  placeholder: PropTypes.string.isRequired
};
