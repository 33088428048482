import React from 'react';
import { NavLink } from 'react-router-dom';

export default class Error404 extends React.Component {
  render() {
    return (
      <section>
        <h1>404 Not Found</h1>
        <p>
          You have stepped onto the
          wrong path and the DM insists you return to&nbsp;
          <NavLink className="navbar-item" activeClassName="is-active" to="/home">
            Home
          </NavLink>.
          <br />
          &lt;The page you are looking for is not found.&gt;
        </p>
      </section>
    );
  }
}
