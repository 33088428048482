import React from 'react';
import PropTypes from 'prop-types';
import ExperienceCard from './card/experience-card';

import './experience.css';

// these constants should be moved to a server side request into the site at a later time.
const EmploymentList = [
  {
    title: "Intermediate DevOps Analyst",
    company: "WestJet",
    location: "Calgary, Alberta",
    period: "Aug 2021 - Present",
    description:
      []
  },
  {
    title: "Owner / Systems Administrator / Developer",
    company: "BTM Technical Solutions Inc. (AB)",
    location: "Calgary, Alberta",
    period: "May 2019 - Present",
    description:
      [
        "Created Code and Scripts to increase ease of use for end users in various languages as required.",
        "Implementing GitLab-CI for faster testing of code in test environments and continuing learning.",
        "Have Implemented git work flow into documentation versioning, code workflows, every day tracking of notes.",
        "Implementing various solutions from Linux to Windows based servers and services for customers."
      ]
  },
  {
    title: "Software Developer",
    company: "Cool IT Systems",
    location: "Calgary, Alberta",
    period: "March 2021 - June 2021",
    description:
      [
        "Completed missing documentation for a fresh build of software and the required Method of Procedure to quickly complete it.",
        "Coded a few micro services in Node.JS to show how we could complete an offline Alpine Linux update with the basic micro service and following required steps.",
        "Pointed out a way to separate the core application from needing hardware to function with a redirection of services.",
        "Proved that the source code could be compiled in a Docker container running qemu emulation"
      ]
  },
  {
    title: "Intermediate Server Administrator (Contractor)",
    company: "WestJet",
    location: "Calgary, Alberta",
    period: "July 2019 - March 2020",
    description:
      [
        "Assisted with General RHEL/Centos, Windows Servers, NetApp, 3Par,  Netbackup, VMWare Adminsitration.",
        "Created a Gitlab-CI pipeline for helping with automation of a system security scan.",
        "Windows Server 2008 R2 migration to Windows Server 2016 by writing a PowerShell script for quicker VMware deployment of Windows 2016 with setting up AD OU location and GPO updates",
        "Improve tape handling process for Iron Mountain Tape pickup.",
        "Assisted in working on team Service Now Tickets.",
        "Manage Disk space for both Windows Server & Azure and Centos/RHEL."
      ]
  },
  {
    title: "Full Stack PHP Developer",
    company: "Shaw Communications",
    location: "Calgary, Alberta",
    period: "Apr 2016 - Dec 2018",
    description:
      [
        "Upgraded internal built CMDB PHP tool from PHP 4 to PHP 5 and to PHP 7.",
        "Improved lag time issues with internal tool within the MySQL Database by enabling features.",
        "Added JavaScript and jQuery to the site for better handling of site loading.",
        "Bug fix and expanded tool for better reporting and functionality.",
        "Built an integration API using JSON to communicate for in-house tool to communicate with Service Now reducing level of efforts of team members.",
        "Implemented git version control system for faster deployment into production."
      ]
  },
  {
    title: "Storage & Backup Analyst",
    company: "Shaw Communications",
    location: "Calgary, Alberta",
    period: "Dec 2011 - Dec 2018",
    description:
      [
        "Linux Systems Administrator for the Backup Infrastructure.",
        "Administration, maintenance, and On-Call Support of NetApp storage systems.",
        "Configured storage for as LUNs or NAS storage for vmware environments, Oracle Databases, and other storage related tasks.",
        "Create bash scripts for management of backup and storage processes.",
        "Setup automatic tape ordering with Iron Mountain's tape management system to remove daily task from team.",
        "Administration and maintenance Veritas NetBackup.",
        "Working and monitoring HP ESL & MSL Tape libraries on a GUI and Command Line interfaces."
      ]
  }
];

const EducationList = [{
  title: "Certificate of Front-End Web Development",
  company: "University of Calgary",
  location: "Calgary, Alberta",
  period: "Oct 2020 - May 2021",
  description:
    [
      "Completed all classes for the certificate with A+."
    ]
},
{
  title: "2D Game Design Certificate",
  company: "Southern Alberta Institute of Technology",
  location: "Calgary, Alberta",
  period: "Jan 2019 - Mar 2019",
  description:
    []
},
{
  title: "Computer Systems Technology",
  company: "Saskatchewan Polytechnic",
  location: "Saskatoon, Saskatchewan",
  period: "Sep 2004 - Sep 2005",
  description:
    []
},
{
  title: "Electronics Engineering Technology",
  company: "Saskatchewan Polytechnic",
  location: "Moose Jaw, Saskatchewan",
  period: "Sep 2003 - Jun 2004",
  description:
    []
}];

export default class Experience extends React.Component {
  constructor(props) {
    super(props);
    const experienceList = (this.props.title === "Employment") ? EmploymentList : EducationList;
    this.state = {
      experienceList
    };
  }

  render() {
    let count = 0;
    return (
      <div>
        <h3>{this.props.title}</h3>
        <ul className="experience-list">
          {
            this.state.experienceList.map(site => <ExperienceCard key={count++} {...site} />)
          }
        </ul>
      </div>
    );
  }

}

Experience.propTypes = {
  title: PropTypes.string.isRequired
};
