import React from 'react';
import SocialMediaBar from '../social-media/social-media-bar';

import './home.css';

export default class Home extends React.Component {
  render() {
    return (
      <section className="home">
        {/* <article>
          <h3 className="title">A little bit About Me</h3>
          <p>
            I am a linux enthusiast and passionate about coding. My preferred working
            environment is on linux where things seems easier to work on for me.
            Mind you I have been using linux since 2001 when my friend&rsquo;s dad showed
            it to me and I have used it ever since.
          </p>
        </article> */}
        <div className="image">
          <img src='/img/brad-500.jpg' alt='Brad Mouck' />
          <SocialMediaBar pageLocation='pagePhoto' />
        </div>
        <article className="home-info">
          <h3>Why thebtm?</h3>
          <p>
            Thebtm is a gamer tag I came up with years ago, and it’s just become part of who I am.
            Along with being a gamer, I’ve been a linux enthusiast for over 20 years from when I
            was first introduced to it.  I am very passionate about coding, as I find the routine
            and challenges to work well with my ADHD (attention deficit hyperactivity disorder)
            and recently diagnosed high-functioning ASD (autism spectrum disorder). Some would
            consider ADHD and ASD to be disabilities, but I consider them to be a gift because
            they enable me to approach projects and tasks differently. It’s just the way I’ve
            always been, but I really feel like I’ve been able to be successful because of them,
            not in spite of them.
          </p>
        </article>
      </section>
    );
  }
}
